<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumbs :links="breadcrumb" />
    <br />
    <button
      type="button"
      @click="$router.push({ path: `/templates/form` })"
      class="bg-green-500 my-2 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full sm:w-1/2 md:w-1/4 xl:w-1/6 font-semibold text-center"
    >
      Novo template
    </button>

    <form class="mb-2" @submit.prevent="setPage(1)">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3">
          <label for="busca" class="block text-sm font-medium text-gray-700">Nome</label>
          <input v-model="filtro.busca" type="text" name="busca" placeholder="Nome do template" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label for="busca" class="block text-sm font-medium text-gray-700">Status</label>
          <v-select
            v-model="filtro.status"
            name="busca"
            :options="opcoesStatus"
            placeholder="Nenhum específico"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label for="busca" class="block text-sm font-medium text-gray-700">Número</label>
          <v-select
            v-model="filtro.numero"
            name="numero"
            label="telefone"
            :options="numeros"
            :reduce="(numero) => numero._id"
            placeholder="Nenhum específico"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-2">
          <button
            type="submit"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-2">
          <button
            type="button"
            @click="limparFiltros()"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Limpar filtros</span>
          </button>
        </div>
      </div>
    </form>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="templates.length > 0">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Categoria</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qualidade</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Número</th>
                  <th scope="col" class="px-2 py-3 mx-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Opções</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="template in templates" :key="template._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ template.nome }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ template.categoria }}
                  </td>
                  <td class="text-sm 2xl:text-base font-medium px-2 py-3">
                    <span :class="`text-${getStatus(template.status)}-500`">
                      {{ template.status }}
                    </span>
                  </td>
                  <td class="text-sm 2xl:text-base font-medium px-2 py-3">
                    <span :class="`text-${getQualidade(template.qualidade)}-500`">
                      {{ template.qualidade }}
                    </span>
                  </td>
                  <td class="text-sm 2xl:text-base font-medium px-2 py-3">
                    {{ template.numero ? template.numero.telefone : "Sem número registrado." }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <button
                      type="button"
                      @click="openModalPreview(template)"
                      class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm mx-1 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                    >
                      Preview
                    </button>
                    <router-link
                      :to="`${route}/form/${template._id}`"
                      type="button"
                      class="bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm mx-1 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                    >
                      Editar
                    </router-link>
                    <button
                      type="button"
                      @click="desativarTemplate(template._id)"
                      class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm mx-1 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                    >
                      Excluir
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="filtro.limit" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
      <div v-else class="mt-5">
        <p class="text-center font-semibold text-zinc-700">Desculpe, sem resultados {{ ":(" }}</p>
        <p class="text-center font-semibold text-gray-400">
          Deseja cadastrar um novo template? Clique
          <span class="text-blue-500 cursor-pointer" @click="$router.push({ path: `/templates/form` })"> aqui </span>
        </p>
      </div>
    </div>
    <modal name="preview" height="auto">
      <div class="px-3 py-2 overflow-auto">
        <Preview
          :edit="false"
          :image="templatePreview.example"
          :document="templatePreview.filename"
          :nome="templatePreview.nome"
          :header="templatePreview.header"
          :examples="templatePreview.examples"
          :body="templatePreview.body"
          :footer="templatePreview.footer"
          :buttons="templatePreview.buttons"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Preview from "./components/Preview.vue";

export default {
  components: { Breadcrumbs, Preview },
  data() {
    return {
      breadcrumb: [{ url: "/templates", value: "Templates Whatsapp" }],
      templates: [],
      templatePreview: {
        nome: "",
        header: "",
        filename: "",
        example: "",
        body: "",
        footer: "",
        buttons: [],
        examples: []
      },
      filtro: {
        busca: "",
        status: "",
        limit: 20,
        numero: null,
        skip: 0
      },
      numeros: [],
      opcoesStatus: ["Em análise", "Aprovado", "Reprovado"],
      page: 1,
      total: 0,
      route: "/templates"
    };
  },
  methods: {
    async start() {
      const response = await this.$http.post(`/v1/${this.route}/1/list`, this.filtro);
      this.templates = response.data.list;
      this.total = response.data.total;
    },

    async setPage(page) {
      this.page = page;
      this.filtro.skip = (page - 1) * this.filtro.limit;

      await this.start();
    },

    async limparFiltros() {
      this.filtro.busca = "";
      this.filtro.status = "";

      await this.setPage(1);
    },

    async desativarTemplate(id) {
      if (!confirm("Tem certeza que deseja remover esse template?")) {
        return;
      }

      const response = await this.$http.delete(`/v1/${this.route}/1/${id}`);

      if (!response.data.success) {
        return this.$vToastify.error(response.data.err);
      }

      await this.setPage(this.page);
      return this.$vToastify.success("Template removido com sucesso.");
    },

    openModalPreview(template) {
      this.templatePreview.nome = template.nome;
      this.templatePreview.header = template.header.text;
      this.templatePreview.example = template.header.example;
      this.templatePreview.filename = template.header.filename;
      this.templatePreview.body = template.body.text;
      this.templatePreview.examples = template.body.examples;
      this.templatePreview.footer = template.footer.text;
      this.templatePreview.buttons = template.buttons;

      this.$modal.show("preview");
    },

    getStatus(status) {
      if (status === "Aprovado") {
        return "green";
      }

      if (status === "Reprovado") {
        return "red";
      }

      return "yellow";
    },

    getQualidade(status) {
      if (status === "Alta") {
        return "green";
      }

      if (status === "Baixa") {
        return "red";
      }

      return "yellow";
    }
  },

  async beforeMount() {
    const response = await this.$http.post(`/v1/numeros/list`, { all: true });
    this.numeros = response.data.list;

    await this.setPage(1);
  }
};
</script>
